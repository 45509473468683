'use client';

import {
  ChakraProvider,
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  VStack,
  Divider,
  Link as ChakraLink,
  useColorModeValue,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { SessionProvider, useSession, signOut } from 'next-auth/react';
import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import NextLink from 'next/link';
import theme from '../theme';
import AuthWrapper from '../components/AuthWrapper';

export default function RootLayout({ children }: { children: ReactNode }) {
  return (
    <html lang="en">
      <body>
        <SessionProvider>
          <ChakraProvider theme={theme}>
            <AuthWrapper>
              <Flex direction="column" minH="100vh">
                <Header />
                <Flex flex="1" mt="60px">
                  {typeof window !== 'undefined' && window.location.hostname !== 'tag.gdn' && (
                    <Sidebar />
                  )}
                  <ContentWrapper>{children}</ContentWrapper>
                </Flex>
              </Flex>
            </AuthWrapper>
          </ChakraProvider>
        </SessionProvider>
      </body>
    </html>
  );
}

const menuItems: { href: string; label: string; adminOnly?: boolean }[] = [
  { href: '/dashboard', label: 'Dashboard', adminOnly: true },
  { href: '/shortener', label: 'URL Shortener' },
  { href: '/domains', label: 'Domain Aggregator', adminOnly: true },
  { href: '/page-redirects', label: 'Domain Redirects', adminOnly: true },
  { href: '/gmail/delegations', label: 'Gmail Delegations', adminOnly: true },
  { href: '/settings', label: 'Settings', adminOnly: true },
];

const Header = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ADMIN_GROUP = process.env.NEXT_PUBLIC_OKTA_ADMIN_GROUP || 'full-access';
  const hoverBg = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    setIsClient(true);
  }, []);

  const userName = session?.user?.name ?? '';
  const userImage = session?.user?.image ?? '';
  const bgColor = useColorModeValue('brand.500', 'brand.900');
  const textColor = useColorModeValue('black', 'white');

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Flex
        as="header"
        width="100%"
        height="60px"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        bg={bgColor}
        color={textColor}
        boxShadow="md"
        position="fixed"
        top="0"
        zIndex="1000"
      >
        {typeof window !== 'undefined' && window.location.hostname !== 'tag.gdn' && (
          <Flex alignItems="center">
            <VStack spacing={0} align="start">
              <Heading size="md" ml={2} color="white">
                ADAPTA.WORKS
              </Heading>
              <Text ml={2} fontSize="xs" color="whiteAlpha.800" fontStyle="italic">
                Early Access
              </Text>
            </VStack>
          </Flex>
        )}
        <Flex alignItems="center">
          <Menu>
            <MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
              <Avatar size="sm" src={userImage} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => signOut()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
          {typeof window !== 'undefined' && window.location.hostname !== 'tag.gdn' && (
            <IconButton
              ml={3}
              aria-label="Open Menu"
              icon={<HamburgerIcon />}
              display={{ base: 'flex', md: 'none' }}
              onClick={onOpen}
            />
          )}
        </Flex>
      </Flex>
      {typeof window !== 'undefined' && window.location.hostname !== 'tag.gdn' && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <VStack align="start">
                {menuItems.filter(item => !item.adminOnly || (session?.groups || []).includes(ADMIN_GROUP)).map((item) => (
                  <NextLink key={item.href} href={item.href} passHref>
                    <ChakraLink
                      fontSize="lg"
                      lineHeight={10}
                      p={0}
                      w="100%"
                      _hover={{ textDecoration: 'none', bg: hoverBg }}
                      onClick={onClose}
                    >
                      {item.label}
                    </ChakraLink>
                  </NextLink>
                ))}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

const Sidebar = () => {
  const { data: session } = useSession();
  const ADMIN_GROUP = process.env.NEXT_PUBLIC_OKTA_ADMIN_GROUP || 'full-access';
  const bg = useColorModeValue('gray.100', 'gray.900');
  const hoverBg = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      as="aside"
      width={{ base: 'full', md: '250px' }}
      bg={bg}
      p={4}
      display={{ base: 'none', md: 'block' }}
    >
      <VStack align="start" spacing={3}>
        {menuItems.filter(item => !item.adminOnly || (session?.groups || []).includes(ADMIN_GROUP)).map((item) => (
          <NextLink key={item.href} href={item.href} passHref>
            <ChakraLink
              p={2}
              fontSize="md"
              w="100%"
              mb={200} 
              _hover={{ textDecoration: 'none', bg: hoverBg }}
            >
              {item.label}
            </ChakraLink>
          </NextLink>
        ))}
      </VStack>
    </Box>
  );
};

const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <Box as="main" flex="1" p={4}>
    {children}
  </Box>
);
